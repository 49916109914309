import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import Container from "../components/Container";
import Layout from "../components/layout";
import SEO from "../components/seo";
import "../components/impressumdatenschutz.css";
import { LanguageContext } from "../context";

const ImpressumDatenschutz = () => {
  const { t } = useTranslation();
  const { getLanguage } = useContext(LanguageContext);
  return (
    <Layout>
      <SEO title={t("imprintdatapolicy")} />
      <div className="w-full bg-safiaBlue h-18"></div>
      <Container backgroundColor="#fff">
        <h1 className="text-h1mobile sm:text-h1desktop text-safiaBlue w-full lg:w-9/12 mb-8">
          {t("imprintdatapolicy")}
        </h1>
        <h2 className="text-h2mobile sm:text-h2desktop text-safiaBlue mb-7">
          {t("imprint")}
        </h2>
        <h3 className="text-h3mobile sm:text-h3desktop text-safiaBlue">
          {t("publisher")}
        </h3>
        <p>{t("publisherDesc")}</p>
        <h3 className="text-h3mobile sm:text-h3desktop text-safiaBlue">
          {t("registerEintrag")}
        </h3>
        <p>{t("registerEintragDesc")}</p>
        <h3 className="text-h3mobile sm:text-h3desktop text-safiaBlue">
          {t("authorizedRepresentative")}
        </h3>
        <p className="w-full lg:w-4/12">{t("authorizedRepresentativeDesc")}</p>

        <h2 className="text-h2mobile sm:text-h2desktop text-safiaBlue mb-7 mt-20">
          {t("privacyPolicy")}
        </h2>
        <h3>{t("responsiblePerson")}</h3>
        <p className="w-full lg:w-6/12">{t("responsiblePersonDesc")}</p>
        <h3>{t("generalInformation")}</h3>
        <p className="w-full lg:w-8/12">{t("generalInformationDesc")}</p>
        <h3>{t("processingActivities")}</h3>
        <h4>{t("operationWebsite")}</h4>
        <h4>{t("provisionWebsite")}</h4>
        <p className="w-full lg:w-8/12">{t("provisionDesc")}</p>
        <p>{t("dataBulletPointDesc")}</p>
        <ul>
          <li>{t("dataBulletPointType")}</li>
          <li>{t("dataBulletPointSystem")}</li>
          <li>{t("dataBulletPointIP")}</li>
          <li>{t("dataBulletPointTime")}</li>
          <li>{t("dataBulletPointRefere")}</li>
          <li>{t("dataBulletPointAccess")}</li>
          <li>{t("dataBulletPointStatusCode")}</li>
          <li>{t("dataBulletPointHTTPVersion")}</li>
          <li>{t("dataBulletPointDataSize")}</li>
        </ul>
        <p className="w-full lg:w-8/12">{t("descSecurity")}</p>
        <h4>{t("useOfCookies")}</h4>
        <p className="w-full lg:w-8/12">{t("useOfCookiesDesc2")}</p>
        <p className="w-full lg:w-8/12">{t("useOfCookiesDesc3")}</p>
        <p className="w-full lg:w-8/12">{t("useOfCookiesDesc4")}</p>
        <p className="w-full lg:w-8/12">{t("useOfCookiesDesc5")}</p>
        <h4>{t("webAnalysisMatomo")}</h4>
        <p className="w-full lg:w-8/12">{t("webAnalysisMatomoDesc1")}</p>
        <p className="w-full lg:w-8/12">{t("webAnalysisMatomoDesc2")}</p>
        <h4>{t("legalBasis")}</h4>
        <p className="w-full lg:w-8/12">{t("legalBasisDesc")}</p>
        <h4>{t("durationOfStorage")}</h4>
        <p className="w-full lg:w-8/12">{t("durationOfStorageDesc")}</p>
        <h4>{t("appeal")}</h4>
        <p className="w-full lg:w-8/12">{t("appealDesc1")}</p>
        <p className="w-full lg:w-8/12">{t("appealDesc2")}</p>
        <div className="w-full lg:w-8/12">
          {getLanguage() === "DE" ? (
            <iframe
              title="Matomo Opt-Out DE"
              style={{ border: 0, height: "200px", width: "100%" }}
              src="https://analytics.safia.tech/index.php?module=CoreAdminHome&action=optOut&language=de&backgroundColor=ffffff&fontColor=140e55&fontSize=16px&fontFamily=Regular"
            ></iframe>
          ) : (
            <iframe
              title="Matomo Opt-Out EN"
              style={{ border: 0, height: "200px", width: "100%" }}
              src="https://analytics.safia.tech/index.php?module=CoreAdminHome&action=optOut&language=en&backgroundColor=ffffff&fontColor=140e55&fontSize=16px&fontFamily=Regular"
            ></iframe>
          )}
        </div>
        <h4>{t("contactFormAndEmail")}</h4>
        <p className="w-full lg:w-8/12">{t("contactFormAndEmailDesc1")}</p>
        <p className="w-full lg:w-8/12">{t("contactFormAndEmailDesc2")}</p>
        <p className="w-full lg:w-8/12">{t("contactFormAndEmailDesc3")}</p>
        <p className="w-full lg:w-8/12">{t("contactFormAndEmailDesc4")}</p>
        <p className="w-full lg:w-8/12">{t("contactFormAndEmailDesc5")}</p>
        <p className="w-full lg:w-8/12">{t("contactFormAndEmailDesc6")}</p>

        <h4>{t("emailDistributionList")}</h4>
        <p className="w-full lg:w-8/12">{t("emailDistributionListDesc1")}</p>
        <p className="w-full lg:w-8/12">{t("emailDistributionListDesc2")}</p>
        <p className="w-full lg:w-8/12">{t("emailDistributionListDesc3")}</p>
        <p className="w-full lg:w-8/12">{t("emailDistributionListDesc4")}</p>
        <ul>
          <li>{t("emailDistributionListLI1")}</li>
          <li>{t("emailDistributionListLI2")}</li>
          <li>{t("emailDistributionListLI3")}</li>
          <li>{t("emailDistributionListLI4")}</li>
        </ul>
        <p className="w-full lg:w-8/12">{t("emailDistributionListDesc5")}</p>
        <p className="w-full lg:w-8/12">{t("emailDistributionListDesc6")}</p>
        <p className="w-full lg:w-8/12">{t("emailDistributionListDesc7")}</p>
        <p className="w-full lg:w-8/12">{t("emailDistributionListDesc8")}</p>

        <h4>{t("rightsDataSubject")}</h4>
        <p className="w-full lg:w-8/12">{t("rightsDataSubjectDesc1")}</p>
        <ul>
          <li>{t("rightsDataSubjectLI1")}</li>
          <li>{t("rightsDataSubjectLI2")}</li>
          <li>{t("rightsDataSubjectLI3")}</li>
          <li>{t("rightsDataSubjectLI4")}</li>
          <li>{t("rightsDataSubjectLI5")}</li>
          <li>{t("rightsDataSubjectLI6")}</li>
          <li>{t("rightsDataSubjectLI7")}</li>
        </ul>
        <p className="w-full lg:w-8/12">{t("rightsDataSubjectDesc2")}</p>
      </Container>
    </Layout>
  );
};

export default ImpressumDatenschutz;
